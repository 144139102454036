<template>
	<div class="page container">
		<div class="page__info">
			<div class="page__info--title">
				Courses
			</div>
			<div class="page__info--subtitle">
				Win more games, improve your skills
			</div>
		</div>
		<div class="separator" />
		<div class="content">
			<div class="side filters">
				<v2-courses-filters
					:filters="filters"
					:search="searchString"
					@update-search="onSearch"
					@update-filter="onFilter"
				/>
			</div>
			<div class="main courses">
				<div class="courses__list">
					<div
						class="create-course"
					>
						Create a new course to see it here
						<V2Button
							to="/teaching/courses/create"
							variant="outline"
							fit
						>
							Create course
						</V2Button>
					</div>
					<v2-course-card
						v-for="course in courses"
						:key="course.id"
						:course="course"
						teacherMode
					/>
				</div>
				<pagination
					v-bind="pagination"
					@paginate="onPaginate"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import api                   from '@/services/api';
	import { experienceLevels }  from '@/consts';
	import { biddingSystems   }  from '@/consts';

	import V2CourseCard  from '@/components/v2/courses/V2CourseCard.vue';
	import V2CoursesFilters from '@/components/v2/browse-courses/V2CoursesFilters.vue';
	import V2Button from '@/components/v2/ui/V2Button';
	import Pagination    from '@/components/ui/views/Pagination';

	export default {
		metaInfo: {
			title: 'Find a course'
		},

		layout: 'v2default',

		components: {
			V2CourseCard,
			V2CoursesFilters,
			V2Button,
			Pagination
		},

		data: () => ({
			courses:        [],
			focus:          [],
			keywords:       [],
			allKeywords:    [],
			searchString:   '',
			filters:  [],
			pagination: {
				perPage:      21,
				currentPage:  1,
				totalPages:   0
			},
			totalItems:     0
		}),

		computed: {

			getBreadcrumbs () {
				return [
					{
						path: '/teaching',
						text: 'Teaching'
					},
					{
						path: '/teaching/courses',
						text: 'Courses'
					}
				];
			},

			getUsersDefaultExperienceLevel () {
				if (!process.env.VUE_APP_ENABLE_DEFAULT_COURSE_FILTERS) {
					return undefined;
				}
				return this.$store.getters['auth/getUsersDefaultExperienceLevel'];
			},

			getUsersDefaultBiddingSystem () {
				if (!process.env.VUE_APP_ENABLE_DEFAULT_COURSE_FILTERS) {
					return undefined;
				}
				return this.$store.getters['auth/getUsersDefaultBiddingSystem'];
			},

			getFilters () {
				return [
					{
						text: 'Experience level',
						value: 'experienceLevel',
						type: 'experienceLevelRange',
						default: this.getUsersDefaultExperienceLevel !== undefined ? [
							this.getUsersDefaultExperienceLevel
						] : undefined,
						options: experienceLevels.slice(0, 8)
					},
					{
						text: 'Bidding system',
						value: 'biddingSystems',
						default: this.getUsersDefaultBiddingSystem !== undefined ? [
							this.getUsersDefaultBiddingSystem
						] : undefined,
						options: biddingSystems.map((biddingSystem) => {
							return {
								text: biddingSystem.value,
								value: biddingSystem.value
							};
						})
					},
					{
						text: 'Focus',
						value: 'focus',
						options: this.focus.map((focus) => {
							return {
								text: focus.focus,
								value: focus.focus
							};
						})
					},
					{
						text: 'Keyword',
						value: 'keywords',
						options: [...new Map(this.getKeywords.map(item => [item.text, item])).values()]
					}
				];
			}
		},

		created () {
			this.setCourses();
		},

		methods: {
			onSearch (value) {
				this.searchString = value;
				this.setCourses();
			},

			onFilter () {
				this.pagination.currentPage = 1;
				this.setCourses();
			},

			onPaginate (value) {
				this.pagination.currentPage = value;
				this.setCourses();
			},

			async setCourses () {
				const data = this.getFilterData();
				const response = await api.teaching.getCourses(data);

				if (!response) {
					this.$store.commit('ui/showError');
					return false;
				}

				this.focus = response.meta.focusKeyword;
				this.allKeywords = response.meta.focusKeyword;
				this.keywords = this.setKeywords(response.meta.focusKeyword);
				this.pagination.totalPages = response.meta.numPages;
				this.pagination.currentPage = response.meta.page;
				this.totalItems = response.meta.totalItems;
				this.courses = response.results;

				this.pagination.totalPages = response.meta.numPages;
				this.pagination.currentPage = response.meta.page;
				this.totalItems = response.meta.totalItems;
				this.courses = response.results;

				this.setFilters(response.meta.filter);

				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});

				this.$store.commit('ui/setLoadingIsHidden');
			},

			getSkillFocusList () {
				return [
					...this.focus.map((item) => ({ label: item.focus, id: item.focus }))
				];
			},

			getKeywords (focusKeywords) {
				const uniqueKeywords = [...new Set(
					focusKeywords.reduce((prev, newVal) => [...prev, ...newVal.keywords], [])
				)];

				return uniqueKeywords.map((item) => ({ label: item, id: item }));
			},

			setKeywords (focusKeywords) {
				return this.getKeywords(focusKeywords);
			},

			getKeywordsList () {
				const selectedFocus = this.filters?.find(({ title }) => title === 'Skill focus')?.selected || [];

        if (selectedFocus.length) {
          const filteredFocusKeywords = this.allKeywords.filter(({ focus }) => selectedFocus.includes(focus));

          return this.getKeywords(filteredFocusKeywords);
        }

				return this.keywords;
			},

			setFilters (filter) {

				const getSelected = (filterTitle) => {
					const selectedValue = this.filters?.find(({ title }) => title === filterTitle)?.selected;

					return selectedValue || [];
				};

				this.filters = [
					{
						type: 'Checkbox',
						title: 'Level',
						values: [
							{ label: 'Level 1', id: 1 },
							{ label: 'Level 2', id: 2 },
							{ label: 'Level 3', id: 3 },
							{ label: 'Level 4', id: 4 },
							{ label: 'Level 5', id: 5 },
							{ label: 'Level 6', id: 6 },
							{ label: 'Level 7', id: 7 },
							{ label: 'Level 8', id: 8 }
						],
						selected: getSelected('Level')
					},
					{
						type: 'Checkbox',
						title: 'Skill focus',
						values: this.getSkillFocusList(),
						selected: getSelected('Skill focus')
					},
					{
						type: 'Checkbox',
						title: 'Bidding System',
						values: [
							{ label: 'Acol', id: 'ACOL' },
							{ label: 'SAYC', id: 'SAYC' },
							{ label: '2 over 1', id: '2-over-1' },
							{ label: 'Majeure Cinquieme', id: 'Majeurs Cinquieme' }
						],
						selected: getSelected('Bidding System')
					},
					{
						type: 'Dropdown',
						title: 'Keyword',
						values: this.getKeywordsList(),
						selected: getSelected('Keyword')
					},
					{
						type: 'Checkbox',
						title: 'Price',
						values: [
							{ label: 'Free', id: '1' },
							{ label: 'Paid', id: '2' }
						],
						selected: getSelected('Price')
					}
				];
			},

			getFilterData () {
        if (!this.filters.length) {
          return {
            include: 'metadata,focus_keyword,only_linked_keywords',
            page: this.pagination.currentPage,
            per: this.pagination.perPage,
            sortBy: 'min_experience_level',
            sortDir: 'ASC'
          };
        }

				const getFilterValue = (filterName) => {
					return this.filters.find(({ title }) => title === filterName).selected.filter((item) => item !== -1);
				};

				return {
					include: 'metadata,focus_keyword,only_linked_keywords',
          searchString: this.searchString,
          filters: {
						experienceLevel: getFilterValue('Level'),
						focus: getFilterValue('Skill focus'),
						biddingSystems: getFilterValue('Bidding System'),
						keywords: getFilterValue('Keyword'),
            price: getFilterValue('Price')
					},
					page: this.pagination.currentPage,
					per: this.pagination.perPage,
					sortBy: 'min_experience_level',
					sortDir: 'ASC'

				};
			}
		}
	};

</script>

<style lang="scss" scoped>
.content {

  @media (max-width: 1279px) {
    display: flex;
    flex-direction: column;

    .side {
      width: 100%;
    }

    .main {
      width: 100%;
    }
  }

  .courses {
    &__list {
      display: grid;
      gap: 12px;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 36px;

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
      }

      .course-card {
        width: 100%
      }

      .create-course {
        width: 100%;
        height: 100%;
        min-height: 315px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 12px 120px;
        text-align: center;
        border: 2px dashed $c-gray-d;
        border-radius: 4px;
        gap: 24px;
        @include font(18px, $c-medium-navy, 400, 23px);

        @media (max-width: 767px) {
          padding: 12px 24px;
        }

        &--btn {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 20px;
          border: 1px solid $c-dark-navy;
          border-radius: 4px;
          @include font(16px, $c-dark-navy, 500, 20px);
        }

      }
    }

    &__list-small {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 12px;
    }

    .border-box {
      border-radius: 4px;
      border: 1px dashed $c-gray-d;
    }

    &__more {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 94px;
      padding: 20px;

      &--text {
        width: 180px;
        @include font(18px, $c-medium-navy, 400, 23px);

        &.mini {
          display: none
        }
      }

    }

    &__list-empty {
      display: flex;
      flex-direction: column;
      gap: 24px;
      height: 335px;
      max-width: 440px;
      width: 100%;
      padding: 12px;
      align-items: center;
      justify-content: center;
      margin-bottom: 36px;

      &--text {
        @include font(18px, $c-medium-navy, 400, 23px);
        text-align: center;
      }

    }
  }
}

</style>
